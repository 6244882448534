/**
* Name: Search component styles
* Author: omeiza@bigcabal.com
 */

.search {
	padding-top: 30px;
	padding-bottom: 30px;

	@media only screen and (max-width: 1199px) {
		padding-top: 10px;
		padding-bottom: 10px;
	}

	&-form input,
	&-meme-type select {
		border-radius: 8px;
		font-family: "ClashGrotesk-Regular", sans-serif;
		width: 100%;
		background-color: var(--theme-input-color);
		border: none;
		font-size: 1.2em;
		color: white;

		@media only screen and (max-width: 1199px) {
			font-size: 0.9em;
		}

		&:focus {
			outline: none;
		}

		&::placeholder {
			color: white;
		}
	}

	&-input {
		padding: 15px 20px;
		background-color: var(--theme-input-color);
		border-radius: 8px;
		display: flex;

		@media only screen and (max-width: 1199px) {
			padding: 10px 15px;
		}

		img {
			width: 20px;
			margin-right: 20px;

			@media only screen and (max-width: 1199px) {
				width: 15px;
			}
		}
	}

	&-meme-type select {
		padding: 15px 20px;
		-webkit-appearance: none;
		-moz-appearance: none;
		height: 100%;

		@media only screen and (max-width: 1199px) {
			padding: 10px 15px;
		}

		&::-ms-expand {
			display: none;
		}

	}

	&-meme-type {
		position: relative;

		&:after {
			position: absolute;
			content: "";
			top: calc(50% - 3px);
			right: 15px;
			width: 0;
			height: 0;
			border: 6px solid transparent;
			border-color: var(--theme-shade-color) transparent transparent transparent;
		}
	}

	&-memes {
		padding-top: 40px;
		padding-bottom: 40px;

		@media only screen and (max-width: 1199px) {
			padding-top: 20px;
			padding-bottom: 20px;
		}

		.heading-title {
			font-family: "ClashGrotesk-Medium", sans-serif;
			font-size: 2.4em;
			display: inline-block;
			text-transform: capitalize;

			@media only screen and (max-width: 1199px) {
				font-size: 1.3em;
			}
		}

		.meme-type-list {
			ul {
				list-style: none;
				margin: 0;
				padding: 30px 0 15px;

				li {
					display: inline-block;
					margin-right: 20px;

					a {
						font-family: "ClashGrotesk-Medium", sans-serif;
						font-size: 1.6em;
						color: white;
						text-decoration: none;

						@media only screen and (max-width: 1199px) {
							font-size: 1em;
						}

						&.active {
							font-family: "ClashGrotesk-Medium", sans-serif;
							color: var(--theme-shade-color);
						}
					}
				}
			}
		}

		&-items {
			ul {
				list-style: none;
				padding: 0;
				margin: 0;

				li {
					margin-bottom: calc((1200px - 40px - (180px * 6))/5);

					a {
						border-radius: 8px;
						display: block;
						width: 200px;
						height: 200px;
						overflow: hidden;

						img {
							display: block;
							min-width: 100%;
							height: 100%;
							transition: transform .25s ease-out;
							box-shadow: 0 4px 10px 0 rgba(0,0,0,.05),0 2px 4px 0 rgba(0,0,0,.08);
							border: 1px solid #1a1a1a;
						}
					}
				}
			}

			&.masonry {
				margin: 0 0 20px;

				li {
					margin-bottom: 20px;
				}

				a {
					width: 100%;
					height: auto;
				}

				img {
					width: 100%;
					height: auto;
					transition: transform .25s ease-out;
					box-shadow: 0 4px 10px 0 rgba(0,0,0,.05),0 2px 4px 0 rgba(0,0,0,.08);
					border: 1px solid #1a1a1a;
				}
			}
		}
	}
}