@import '../../styles/mixin.scss';

$gridGap: 20px;

.grid-listing {
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	width: 100%;
	overflow: hidden;
	gap: $gridGap;
	list-style-type: none;
	margin: 0;
	padding: 0;

	@include desktop {
		&.columns-5 { grid-template-columns: repeat(5, 1fr); }
		&.columns-4 { grid-template-columns: repeat(4, 1fr); }
		&.columns-3 { grid-template-columns: repeat(3, 1fr); }
		&.columns-2 { grid-template-columns: repeat(2, 1fr); }
		&.columns-1 { grid-template-columns: repeat(1, 1fr); }
	}

	@include mobile {
		color: red;
		grid-template-columns: repeat(2, 1fr);

		&.mobile-columns-1 { grid-template-columns: repeat(1, 1fr); }
		&.mobile-columns-2 { grid-template-columns: repeat(2, 1fr); }
		&.mobile-columns-3 { grid-template-columns: repeat(3, 1fr); }
		&.mobile-columns-4 { grid-template-columns: repeat(4, 1fr); }
	}

	@include desktop {
		&.big-first {
			li:first-child {
				grid-column-start: 1;
				grid-column-end: 3;
				grid-row-start: 1;
				grid-row-end: 3;
			}
		}
	}

	&.scroller {
		// columns are now setting how many visible columns. There should
		// also be a bit of the next one displaying
		// count determines the amount of columns (count maxes to content length)

		// default to 6
		grid-template-columns: none !important;
		grid-auto-columns: calc( 100vw / 6.5 - $gridGap );
		grid-auto-flow: column; /* this will do the trick */	  
		overflow: scroll;

		li { min-width: 100%; }

		@include desktop {
			@for $i from 1 through 5 {
				&.columns-#{$i} {
					grid-auto-columns: calc( 100% / #{$i + 0.15} - #{$gridGap} );
				}
			}
		}
		@include mobile {
			@for $i from 1 through 5 {
				&.mobile-columns-#{$i} {
					grid-auto-columns: calc( 100% / #{1 + 0.15} - #{$gridGap} );
				}
			}					
		}
	}
	&.masonry {
		display: block;
		.masonry-inner {
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			@include mobile {
				grid-template-columns: repeat(2, 1fr);
			}
			gap: 20px;
			.partition {
				width: 100% !important;

				li {
					padding-bottom: 20px;
					.thumb {
						height: auto;
						padding: 0;
						img {
							position: static;
						}
					}
				}

			}
		}
	}
	// &.dynamic {
	// 	display: block;
	// 	column-count: 4;
	// 	column-gap: 20px;
	// 	column-fill: balance;

	// 	li {
	// 		padding-bottom: 20px;
	// 		.thumb {
	// 			height: auto;
	// 			padding: 0;
	// 			img {
	// 				position: static;
	// 			}
	// 		}
	// 	}
	// }


	// Items

	li {
		margin: 0 !important;
		.placeholder {
			display: block;
			width: 100%;
			height: 0;
			padding-bottom: 100%;
			// border: 1px solid rgba(255,255,255,0.1);
			background: rgba(255,255,255,0.1);
			// border-radius: 8px;
		}
		a {
			display: flex;
			flex-direction: column;
			text-decoration: none;
			.thumb {
				display: block;
				width: 100%;
				height: 0px;
				padding-bottom: 100%;
				position: relative;
				border-radius: 8px;
				overflow: hidden;
				img {
					position: absolute;
					top: 0; left: 0;
					right: 0; bottom: 0;
					width: 100%;
					height: 100%;
					display: block;
					object-fit: cover;
				}
			}
			.label {
				display: block;
				line-height: 1.4em;
				max-height: 2.8em;
				overflow: hidden;
				color: white;
				font-weight: bold;
				margin-top: 0.5em;
			}
		}
	}

	&.label-overlay {
		li {
			position: relative;
			h3, .label {
				position: absolute;
				background: rgba(0,0,0,0.5);
				color: white;
				padding: 40px 20px;
				margin: 0;
				top: 0; left: 0; right: 0; bottom: 0;
				display: flex;
				align-items: flex-end;
				font-size: 24px;
				max-height: none;
				@include mobile {
					padding: 30px 15px;
					font-size: 14pt;
				}
			}
		}
	}
}

// === FORMATS ===

.grid-listing.zikoko-articles {
	li {
		a {
			.thumb {
				padding-bottom: 50%;
				border-radius: 8px;
				overflow: hidden;
			}
		}
	}
}


// === Various stuff to move elsewhere
// === All/GIF/Images

ul.filters {
	list-style-type: none;
	padding: 0;
	margin: 0;
	display: flex;
	flex-direction: row;
	li {
		font-size: 1.6em;
		color: white;
		font-weight: bold;
		padding: 20px 20px 0 0;
		cursor: pointer;

		&.active {
			color: var(--theme-shade-color);
		}

		@media only screen and (max-width: 1199px) {
			font-size: 1.1em;
			padding-top: 10px;
		}
	}
}

.scroll-arrows {
	list-style-type: none;
	padding: 0;
	margin: 0;
	display: flex;
	flex-direction: row;
	float: right;
	gap: 20px;
	li {
		&.disabled {
			opacity: 0.5;
		}
		img {
			width: 30px;
			height: 30px;
		}
	}
}
.list-section .heading-section {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: baseline;

	@media only screen and (max-width: 1199px) {
		flex-direction: column;
	}
}


.user-profile {
	display: flex;
	flex-direction: row;
	gap: 5px;
	align-items: center;
	align-content: center;
	img {
		width: auto;
		height: 1.4em;
	}
}