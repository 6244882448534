/**
* Name: Hot component styles
* Author: omeiza@bigcabal.com
 */

.hot {
	padding-top: 40px;
	padding-bottom: 40px;

	&-items {
		ul {
			list-style: none;
			padding: 0;
			margin: 0;

			li {
				margin-bottom: calc((1200px - 40px - (180px * 6))/5);

				@media only screen and (max-width: 1199px) {
					margin-bottom: 0;
				}

				a {
					border-radius: 8px;
					display: block;
					width: 275px;
					height: 275px;
					overflow: hidden;
					position: relative;

					@media only screen and (max-width: 1199px) {
						width: 100%;
						height: 150px;
					}

					img {
						display: block;
						min-width: 100%;
						height: 100%;
						box-shadow: 0 4px 10px 0rgba(0,0,0,.05),0 2px 4px 0 rgba(0,0,0,.08);
						border: 1px solid #1a1a1a;
					}

					h3 {
						font-size: 1.35em;
						font-family: "ClashGrotesk-Regular", sans-serif;
						background-color: rgba(#0B0014, 0.6);
						color: white;
						display: flex;
						align-items: flex-end;
						padding: 30px 20px;
						position: absolute;
						bottom: 0;
						left: 0;
						margin: 0;
						width: 100%;
						height: 100%;

						@media only screen and (max-width: 1199px) {
							padding: 15px;
							font-size: 1em;
						}
					}
				}

				&:nth-of-type(5),
				&:nth-of-type(6),
				&:nth-of-type(7),
				&:nth-of-type(8),
				&:nth-of-type(9) {
					a {
						width: 100%;
						height: 275px;

						@media only screen and (max-width: 1199px) {
							width: 100%;
							height: 150px;
						}
					}

					img {
						width: 100%;
						height: auto;
					}
				}
			}
		}
	}

	&-all,
	&-single {
		padding-top: 40px;
		padding-bottom: 40px;

		.heading-title {
			font-family: "ClashGrotesk-Medium", sans-serif;
			font-size: 2.4em;
			display: inline-block;
			font-weight: 400;
		}
	}

	&-single {
		.meme-type-list {
			ul {
				list-style: none;
				margin: 0;
				padding: 30px 0 15px;

				li {
					display: inline-block;
					margin-right: 20px;

					a {
						font-family: "ClashGrotesk-Medium", sans-serif;
						font-size: 1.6em;
						color: white;
						text-decoration: none;

						&.active {
							font-family: "ClashGrotesk-Medium", sans-serif;
							color: var(--theme-shade-color);
						}
					}
				}
			}
		}

		&-items {
			ul {
				list-style: none;
				padding: 0;
				margin: 0;

				li {
					margin-bottom: calc((1200px - 40px - (180px * 6))/5);

					a {
						border-radius: 8px;
						display: block;
						width: 200px;
						height: 200px;
						overflow: hidden;

						img {
							display: block;
							min-width: 100%;
							height: 100%;
							box-shadow: 0 4px 10px 0rgba(0,0,0,.05),0 2px 4px 0 rgba(0,0,0,.08);
							border: 1px solid #1a1a1a;
						}
					}
				}
			}

			&.masonry {
				margin: 0 0 20px;

				li {
					margin-bottom: 20px;
				}

				a {
					width: 100%;
					height: auto;
				}

				img {
					width: 100%;
					height: auto;
					box-shadow: 0 4px 10px 0rgba(0,0,0,.05),0 2px 4px 0 rgba(0,0,0,.08);
					border: 1px solid #1a1a1a;
				}
			}
		}
	}
}
