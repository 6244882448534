/**
* Name: Header component styles
* Author: omeiza@bigcabal.com
 */

.header {
	padding: 40px 0;

	@media only screen and (max-width: 1199px) {
		padding: 15px 0;

		.logo {
			img {
				width: 95px;
			}
		}
	}

	&-status {
		display: flex;
		align-items: center;
	}

	.icon {
		margin-left: 30px;
		cursor: pointer;

		@media only screen and (max-width: 1199px) {
			margin: 0 0 0 10px;

			img {
				width: 22px;
			}
		}
	}

	.btn {
		margin-right: 20px;

		@media only screen and (max-width: 1199px) {
			margin-right: 0;
		}
	}
}