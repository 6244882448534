/**
* Name: User component styles
* Author: omeiza@bigcabal.com
 */

.profile {
	padding: 40px 0;

	@media only screen and (max-width: 1199px) {
		padding: 20px 0;
	}

	.heading-title {
		font-size: 2.4em;
		display: inline-block;
		font-weight: 400;

		@media only screen and (max-width: 1199px) {
			font-size: 1.6em;
		}
	}

	&-wrapper {
		grid-template-columns: 3fr 6fr;
		grid-gap: 40px;

		@media only screen and (max-width: 1199px) {
			display: block;
		}

		.form-success-message,
		.form-error-message {
			margin-top: 0;
		}
	}

	&-info-main {
		background-color: var(--theme-input-color);
	}

	&-info-main {
		border-radius: 8px;
		padding: 40px;

		@media only screen and (max-width: 1199px) {
			padding: 20px;
		}
	}

	&-info {
		position: sticky;
		top: 20px;
		max-height: 50%;

		@media only screen and (max-width: 1199px) {
			position: static;
			margin-bottom: 40px;
		}

		.heading-section {
			grid-template-columns: auto auto;
			grid-gap: 20px;
			align-items: center;
			justify-content: space-between;

			img {
				width: 18px;
				margin-left: 10px;
			}
		}

		&-leaderboard {
			a {
				color: inherit;
				display: flex;
				align-items: flex-start;
				text-decoration: none;
				font-size: 1.1em;
				font-family: "ClashGrotesk-Medium", sans-serif;

				@media only screen and (max-width: 1199px) {
					font-size: 0.85em;
				}
			}
		}

		&-img {
			img {
				width: 150px;
				border-radius: 100%;
				margin: 0 auto;
				display: block;
			}
		}

		&-username,
		&-img {
			text-align: center;
			font-family: "ClashGrotesk-Medium", sans-serif;
			font-size: 1.2em;
		}

		&-img {
			width: 150px;
			height: 150px;
			margin: 0 auto 30px;
			background-size: cover;
			background-position: center;
			border-radius: 100%;

			img {
				width: 100%;
				height: auto;
			}
		}
		&-metrics {
			text-align: center;
			margin: 30px 0;
			padding: 0;

			li {
				display: inline-block;
				padding: 0 20px;
				font-family: "ClashGrotesk-Medium", sans-serif;
				font-size: 1.2em;
				color: rgba(#FFFFFF, 0.6);

				&:first-of-type {
					border-right: 1px solid rgba(#FFFFFF, 0.6);
				}

				span {
					color: white;
					font-family: "ClashGrotesk-Bold", sans-serif;
					background: -webkit-linear-gradient(left, #5EE2B4, #A196FF);
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
				}
			}
		}

		&-description {
			font-size: 1.2em;
		}

		&-edit {
			margin-top: 30px;
			text-align: center;
			background-color: #261C2E;
		}
	}

	&-edit {
		background-color: #1c1124;
		border-radius: 8px;
		padding: 60px 40px;

		@media only screen and (max-width: 1199px) {
			padding: 20px 30px;
		}

		form {
			max-width: 400px;
			margin: 0 auto;
		}
	}
}



section.profile .filters {
	list-style: none;
	margin: 1px auto 45px;
	padding: 0;

	@media only screen and (max-width: 1199px) {
		margin: 0 0 30px;
	}

	li {
		display: inline-block;

			text-decoration: none;
			color: white;
			font-family: "ClashGrotesk-Medium", sans-serif;
			font-size: 1.1em;
			padding: 0 20px 10px;
			border-bottom: 3px solid rgba(#A196FF, 0.3);

			@media only screen and (max-width: 1199px) {
				font-size: 0.9em;
			}

			&.active {
				border-color: #A196FF;
			}
	}
}
