/**
* Name: Hot component styles
* Author: omeiza@bigcabal.com
 */


// Meme
.meme {
	&-heading {
		display: grid;
		grid-template-columns: auto auto;
		justify-content: space-between;
		border-bottom: 1px solid #261C2E;
		margin: 0 0 15px;
		padding-bottom: 15px;
		grid-gap: 15px;

		h1 {
			text-transform: capitalize;
			font-family: "ClashGrotesk-Medium", sans-serif;
			font-size: 2.4em;
			line-height: 1.1;
			margin: 0;

			@media only screen and (max-width: 1199px) {
				font-size: 1.8em;
			}
		}
	}
	
	&-create-button {
		cursor: pointer;
	}

	&-contributor {
		font-family: "ClashGrotesk-Medium", sans-serif;
		margin-bottom: 20px;
		color: rgba(#FFFFFF, 0.75);

		a {
			text-decoration: none;
			color: inherit;

			@media only screen and (max-width: 1199px) {
				font-size: 0.9em;
			}
		}
	}

	&-count {
		font-size: 0.9em;
		display: flex;
		align-items: center;
		font-family: "ClashGrotesk-Medium", sans-serif;

		@media only screen and (max-width: 1199px) {
			font-size: 0.8em;
		}

		img {
			width: 16px;
			height: auto;
			margin-left: 10px;

			@media only screen and (max-width: 1199px) {
				width: 14px;
			}
		}
	}

	&-image {
		position: relative;
		display: flex;

		@media only screen and (max-width: 1199px) {
			display: block;
			width: 100%;
		}

		&-main {
			width: 100%;
			height: auto;
			border-radius: 12px;
			//margin-bottom: 30px;
			margin-right: 30px;
			min-width: 100%;

			@media only screen and (max-width: 1199px) {
				min-width: 100%;
				margin-right: 0;
				margin-bottom: 20px;
			}
		}

		&-like {
			cursor: pointer;
			position: absolute;
			width: 25px;
			right: 20px;
			top: 20px;
		}
	}

	&-uploader {
		&-text {
			position: absolute;
			left: calc(50% - 75px);
			top: calc(50% - 20px);
			width: auto;
			text-align: center;
			display: flex;
			flex-direction: column;
			z-index: 3;

			p {
				color: rgba(white, 0.7);
				margin-top: 10px;
			}
		}

		&-notice {
			position: relative;
			height: fit-content;

			&:after {
				content: "";
				position: absolute;
				width: 100%;
				height: 100%;
				background-color: rgba(black, 0.5);
				z-index: 2;
				left: 0;
				top: 0;
			}

			.upload-edit {
				border-radius: 5px;
				padding: 10px 15px;
				border-width: 2px;
				margin: 0 auto;
				cursor: pointer;
			}
		}
	}

	&-actions {
		position: sticky;
		left: calc(100% + 40px);
		top: 20px;
		height: 100%;

		.inactive {
			opacity: 0.5;
		}


		@media only screen and (max-width: 1199px) {
			position: static;
		}

		ul {
			list-style: none;
			margin: 0;
			padding: 0;

			@media only screen and (max-width: 1199px) {
				display: flex;
				justify-content: space-between;
			}

			li {
				font-family: "ClashGrotesk-Medium", sans-serif;
				align-items: center;
				color: rgba(#FFFFFF, 0.75);
				margin-bottom: 30px;

				@media only screen and (max-width: 1199px) {
					font-size: 0.75em;
					margin-bottom: 0;
					margin-right: 15px;

					&:last-of-type {
						margin-right: 0;
					}
				}

				&:last-of-type {
					margin: 0;
				}

				a, span {
					display: flex;
					text-decoration: none;
					color: white;
					align-items: center;
					cursor: pointer;
				}
			}
		}

		img {
			width: 24px;
			margin-right: 15px;

			@media only screen and (max-width: 1199px) {
				height: 18px;
				margin-right: 5px;
			}
		}
	}

	&-tag-cloud {
		margin-top: 30px;

		a {
			font-size: 0.85em;
			font-family: "ClashGrotesk-Medium", sans-serif;
			border-radius: 4px;
			padding: 10px 15px;
			text-decoration: none;
			color: white;
			background-color: #170D20;
			margin-right: 10px;
			margin-bottom: 10px;
			display: inline-block;

			@media only screen and (max-width: 1199px) {
				font-size: 0.75em;
				padding: 7px 10px;
				margin-right: 5px;
				margin-bottom: 5px;
			}
		}
	}

	&-items {
		ul {
			list-style: none;
			padding: 0;
			margin: 0;

			li {
				margin-bottom: calc((1200px - 40px - (180px * 6))/5);
			}
		}

		a {
			border-radius: 8px;
			display: block;
			width: 180px;
			height: 180px;
			overflow: hidden;
		}

		img {
			display: block;
			min-width: 100%;
			height: 100%;
			transition: transform .25s ease-out;
			box-shadow: 0 4px 10px 0 rgba(0,0,0,.05), 0 2px 4px 0 rgba(0,0,0,.08);
			border: 1px solid #1a1a1a;
		}
	}

	&-upload {
		.meme {
			&-heading {
				border-bottom: none;
			}
		}

		&-success {
			img {
				width: 100% !important;
				margin-top: 20px;
			}
		}
	}

	&-report {
		max-width: 380px;
		width: 100%;
		background-color: var(--theme-input-color);
		height: auto;
		position: relative;
		top: 100px;
		padding: 0 0 30px 0;
		margin: auto;

		@media only screen and (max-width: 1199px) {
			max-width: 330px;
		}

		&-heading {
			display: flex;
			justify-content: space-between;
			padding: 20px 30px;
			border-bottom: 1px solid rgba(white, 0.15);

			.menu-close {
				position: static;
			}

			h2 {
				font-family: "ClashGrotesk-Bold", sans-serif;
				margin: 0;
				font-size: 1.6em;
				font-weight: 400;
				display: flex;

				img {
					width: 20px;
					margin-right: 20px;
				}
			}
		}

		&-details {
			h3 {
				padding: 20px 30px;
				font-family: "ClashGrotesk-Medium", sans-serif;
				margin: 0;
				font-size: 1.4em;
				font-weight: 400;
				color: rgba(255, 255, 255, 0.85);

				@media only screen and (max-width: 1199px) {
					font-size: 1.2em;
				}
			}

			form {
				padding: 20px 30px;

				.form-password-main {
					padding: 0;
				}

				textarea {
					min-height: 150px;
				}
			}

			ul {
				margin: 0;
				padding: 0;
				list-style: none;

				li {
					font-family: "ClashGrotesk-Medium", sans-serif;
					padding: 20px 30px;
					font-size: 1em;
					display: flex;
					justify-content: space-between;
					align-items: center;
					cursor: pointer;
					color: rgba(255, 255, 255, 0.65);

					@media only screen and (max-width: 1199px) {
						padding: 15px 30px;
						font-size: 0.9em;
					}

					&:hover {
						background-color: #2D2435;
					}
				}

				&:last-of-type {
					margin-top: 30px;

					li {
						background-color: #2D2435;
					}
				}
			}

			img {
				width: 7px;
			}
		}

		&-submitted {
			padding: 0 30px;

			h3 {
				padding-left: 0;
				padding-right: 0;
			}

			p {
				font-size: 1.1em;
				line-height: 1.3;
				color: rgba(255, 255, 255, 0.65);

				@media only screen and (max-width: 1199px) {
					font-size: 1em;
				}

				&:first-of-type {
					margin-top: 0;
				}
			}

			.btn {
				text-align: center;
				margin-top: 20px;
				cursor: pointer;
			}
		}
	}

	&-uploader {
		width: 380px;
		background-color: var(--theme-input-color);
		height: auto;
		position: relative;
		top: 50px;
		margin: auto;
		padding: 0 0 30px 0;

		@media only screen and (max-width: 1199px) {
			width: 300px;
			margin: 0 auto;
			left: auto;
		}
	}

	&-share-social {
		padding: 0;
		margin: 40px 0 0 0 !important;
		list-style: none;
		display: flex;
		justify-content: space-between;

		&:last-of-type li,
		li {
			background-color: #1c1124 !important;
			padding: 0;

			img {
				width: auto;
				height: 40px;

				@media only screen and (max-width: 1199px) {
					height: 35px;
				}
			}
		}
	}

	&-empty {
		border-radius: 8px;
		padding: 60px 40px;
		background-color: #1c1124;

		@media only screen and (max-width: 1199px) {
			padding: 30px 20px;
		}

		&-content {
			width: 400px;
			margin: 0 auto;
			position: relative;

			@media only screen and (max-width: 1199px) {
				width: 100%;
			}

			p {
				font-size: 1.2em;

				@media only screen and (max-width: 1199px) {
					font-size: 1em;
				}
			}

			img {
				width: 100px;
				background-color: #A196FF;
				border-radius: 100%;
			}

			a {
				max-width: 250px;
			}
		}
	}
}

// zikoko-articles
.zikoko-articles {
	&-heading {
		display: flex;
		//grid-template-columns: auto 100px;
		//grid-gap: 40px;
		align-items: center;
		margin-bottom: 20px;
		justify-content: space-between;

		h2 {
			font-size: 2.4em;
			display: inline-block;
			margin: 0;

			@media only screen and (max-width: 1199px) {
				font-size: 1.6em;
			}
		}
	}

	&-scroller {
		list-style: none;
		display: flex;
		padding: 0;
		margin: 0;

		li {
			margin-left: 20px;

			@media only screen and (max-width: 1199px) {
				margin-left: 15px;
			}

			img {
				height: 28px;
				width: auto;
				display: block;

				@media only screen and (max-width: 1199px) {
					height: 22px;
				}
			}
		}
	}
}

.more-report-p {
	margin-top: 30px;
}


.meme,
.related-memes,
.zikoko-articles {
	padding-top: 40px;
	padding-bottom: 40px;

	@media only screen and (max-width: 1199px) {
		padding-top: 20px;
		padding-bottom: 20px;
	}
}

// FileRobot image editor

.FIE_polygon-tool-button, .FIE_annotation-option-triggerer[title="Shadow"] {
	display: none !important;
}