/**
* Name: Layout styles
* Author: omeiza@bigcabal.com
 */

.container {
	margin: 0 auto;
	padding: 0 20px;
	width: var(--content-width);
	max-width: var(--max-content-width);
	position: relative;

	@media only screen and (max-width: 1199px) {
		padding: 0 15px;
	}

	&-auth {
		position: relative;
		width: var(--content-width);
		max-width: 380px;
		z-index: 2;
		top: 50px;
	}
}

.ad,
.mpu {
	margin: 10px auto;

	p {
		margin: 0 0 5px;
		font-size: 0.75em;
		text-align: center;
		text-transform: uppercase;
		letter-spacing: 2px;
		color: #d0cbcb;
		font-weight: 500;
	}

	&.at-mobile {
		display: none;
	}

	@media (max-width: 768px) {
		&.at-mobile {
			display: block;
			width: 100%;
			padding: 0 !important;

			.adunitContainer {
				margin: 5px 0;
			}
		}

		&.at-desktop {
			display: none;
		}
	}
}

.mpu {
	margin: 0 0 20px;
}

.adunitContainer {
	margin: 10px 0;
	text-align: center;
}

// Masonry
.masonry-grid {
	display: -webkit-box; /* Not needed if autoprefixing */
	display: -ms-flexbox; /* Not needed if autoprefixing */
	display: flex;
	margin-left: -20px; /* gutter size offset */
	width: auto;

	&_column {
		padding-left: 20px; /* gutter size */
		background-clip: padding-box;

		& > div {
			background: grey;
			margin-bottom: 20px;
		}
	}
}

// Page
.page {
	&-header {
		padding: 40px 0;
		align-items: center;
		border-bottom: 1px solid var(--theme-color);

		@media only screen and (max-width: 1199px) {
			padding: 20px 0;
		}

		div {
			font-size: 1.1em;
			font-family: "ClashGrotesk-Bold", sans-serif;

			span {
				font-family: "ClashGrotesk-Regular", sans-serif;
				display: block;
				margin-top: 3px;
			}
		}

		h1 {
			margin: 0;
			font-size: 3em;
			font-family: "ClashGrotesk-Bold", sans-serif;
			line-height: 1.1;

			@media only screen and (max-width: 1199px) {
				font-size: 2em;
			}
		}
	}

	&-content {
		padding: 40px 0;

		@media only screen and (max-width: 1199px) {
			padding: 20px 0;
		}

		&-main {
			font-family: "ClashGrotesk-Regular", sans-serif;
			overflow-y: auto;
			// height: calc(100vh - 40px - 190px - 130px);

			@media only screen and (max-width: 1199px) {
				overflow-y: auto;
				height: auto;
			}

			h2, h3 {
				margin-top: 30px;
			}

			h2 {
				font-family: "ClashGrotesk-Bold", sans-serif;
				font-size: 1.8em;

				@media only screen and (max-width: 1199px) {
					font-size: 1.4em;
				}
			}

			h3 {
				font-size: 1.25em;
				color: white;
				font-family: "ClashGrotesk-Bold", sans-serif;

				@media only screen and (max-width: 1199px) {
					font-size: 1.2em;
				}
			}

			p, li {
				font-size: 1.1em;
				color: #B4B4B4;
				line-height: 1.6em;

				@media only screen and (max-width: 1199px) {
					line-height: 1.4em;
				}
			}

			&:not(.info-content) {
				ol {
					list-style: none;
					counter-reset: item;
					margin: 0;
					padding: 0;

					li {
						counter-increment: item;
						margin-bottom: 5px;

						h3 {
							display: inline-block;
							margin-bottom: 10px;
							margin-top: 10px;

							&:before {
								content: ".";
								margin-right: 10px;
							}
						}

						p {
							margin-top: 0;
						}
					}

					li:before {
						font-family: "ClashGrotesk-Bold", sans-serif;
						margin-right: 0;
						content: counter(item);
						font-size: 1.4em;
						text-align: center;
					}
				}
			}
		}
	}

	&-anchors {
		padding: 20px 180px 20px 0;
		margin: 0;
		list-style: none;

		@media only screen and (max-width: 1199px) {
			display: none;
		}

		li {
			margin-bottom: 30px;
		}

		a {
			font-size: 1.1em;
			color: inherit;
			text-decoration: none;
			font-family: "ClashGrotesk-Regular", sans-serif;
		}
	}
}

// Notfound 404
.not-found {
	padding: 40px 0;
	grid-template-columns: 360px 50%;
	justify-content: space-between;
	grid-gap: 80px;
	align-items: center;

	img {
		width: 100%;
	}

	h2 {
		font-size: 3em;
		font-family: "ClashGrotesk-Bold", sans-serif;
		line-height: 1.1;
		margin-bottom: 15px;
		margin-top: 0;
	}

	p {
		font-size: 1.2em;
		line-height: 1.45;
	}

	a {
		color: inherit;
	}

	@media only screen and (max-width: 1199px) {
		display: flex;
		flex-direction: column;
		gap: 20px;
		h2 {
			font-size: 2em;
		}
	}
}

// About
.about {
	&-intro-wrapper {
		grid-template-columns: 3fr 2fr;
		align-items: center;

		@media only screen and (max-width: 1199px) {
			margin: 20px 0;
			display: flex;
			flex-direction: column;
		}
	}

	&-intro {
		padding-left: calc((100vw - 1160px) / 2);
		width: 585px;

		@media only screen and (max-width: 1199px) {
			order: 2;
			padding: 30px 15px;
			width: 100%;
		}

		h1 {
			font-size: 4em;
			font-weight: 400;
			font-family: "ClashGrotesk-Bold", sans-serif;
			margin: 0 0 10px;
			line-height: 1.1;

			@media only screen and (max-width: 1199px) {
				font-size: 2.6em;
				line-height: 1;
			}
		}

		p {
			font-size: 1.3em;
			line-height: 1.4;
			margin-bottom: 30px;
			color: rgba(white, 0.7);

			@media only screen and (max-width: 1199px) {
				font-size: 1.1em;
			}
		}

		a {
			text-align: center;

			@media only screen and (max-width: 1199px) {
				font-size: 1.2em;
				padding: 15px 20px;
			}
		}

		&-variable {
			font-family: 'Fraunces', serif;
			font-style: italic;
		}

		&-colored {
			background: -webkit-linear-gradient(left, #5EE2B4, #A196FF);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}
	}

	&-content {
		padding-top: 80px;
		padding-bottom: 80px;

		@media only screen and (max-width: 1199px) {
			padding-top: 40px;
			padding-bottom: 40px;
		}

		&-wrapper {
			grid-template-columns: 1fr 1fr;
			grid-gap: 80px;
			align-items: center;

			@media only screen and (max-width: 1199px) {
				font-size: 1em;
				display: flex;
				flex-direction: column;
			}
		}

		&-main {
			@media only screen and (max-width: 1199px) {
				width: 100%;
			}

			span {
				font-family: "ClashGrotesk-Bold", sans-serif;
				color: #5EE2B4;
				font-size: 1.2em;

				@media only screen and (max-width: 1199px) {
					font-size: 1em;
				}
			}

			h2 {
				font-family: "ClashGrotesk-Bold", sans-serif;
				font-size: 3em;
				width: 400px;
				margin-top: 10px;
				margin-bottom: 25px;
				line-height: 1;

				@media only screen and (max-width: 1199px) {
					font-size: 2.2em;
					width: 100%;
				}
			}

			p {
				font-size: 1.3em;
				line-height: 1.4;
				margin-bottom: 20px;
				color: rgba(white, 0.7);

				@media only screen and (max-width: 1199px) {
					font-size: 1.1em;
				}
			}
		}
	}

	&-jumbotron {
		@media only screen and (max-width: 1199px) {
			order: 1;
		}

		img {
			width: auto;
			height: 100%;

			&.at-mobile {
				display: none;
			}

			@media only screen and (max-width: 1199px) {
				&.at-mobile {
					display: block;
					width: 100%;
				}

				&.at-desktop {
					display: none;
				}
			}
		}
	}

	&-description {
		padding-bottom: 120px;

		@media only screen and (max-width: 1199px) {
			padding-bottom: 40px;
		}

		&-content {
			width: 600px;
			margin: 0 auto;

			@media only screen and (max-width: 1199px) {
				max-width: 400px;
				width: 100%;
			}

			h2 {
				margin-bottom: 60px;
				font-weight: 400;
				font-family: "ClashGrotesk-Bold", sans-serif;
				font-size: 2.4em;
				margin-top: 10px;
				line-height: 1.15;
				text-align: center;

				@media only screen and (max-width: 1199px) {
					font-size: 1.8em;
				}
			}

			span {
				font-family: 'Fraunces', serif;
				font-style: italic;
			}
		}
	}

	&-value-list {
		ul {
			display: flex;
			align-items: center;
			text-align: center;
			list-style: none;
			margin: 0;
			padding: 0;

			@media only screen and (max-width: 1199px) {
				display: grid;
				grid-template-columns: repeat(2, auto);
				grid-gap: 30px;
			}

			li {
				display: inline-block;
				padding: 0 30px;
				font-size: 1.1em;

				&:last-of-type {
					margin: 0;
				}
			}
		}

		img {
			height: 50px;
			display: block;
			margin: 0 auto 20px;

			@media only screen and (max-width: 1199px) {
				margin: 0 auto 10px;
				height: 40px;
			}
		}
	}

	&-how-it-works {
		text-align: center;

		h2 {
			margin-bottom: 40px;
			font-weight: 400;
			font-family: "ClashGrotesk-Bold", sans-serif;
			font-size: 2em;

			@media only screen and (max-width: 1199px) {
				margin-bottom: 20px;
				font-size: 1.6em;
			}
		}

		ul {
			list-style: none;
			grid-template-columns: repeat(2, 1fr);
			grid-gap: 40px;
			width: 1000px;
			margin: 0 auto;
			padding: 0;

			@media only screen and (max-width: 1199px) {
				grid-gap: 20px;
				width: 100%;
				display: flex;
				flex-direction: column;
			}

			li {
				border: 1px solid rgba(white, 0.25);
				padding: 40px;
				border-radius: 8px;

				@media only screen and (max-width: 1199px) {
					padding: 30px 20px;
				}

				p {
					font-size: 1.3em;
					line-height: 1.4;
					color: rgba(white, 0.7);

					@media only screen and (max-width: 1199px) {
						font-size: 1.1em;
					}
				}

				h3 {
					font-family: "ClashGrotesk-Bold", sans-serif;
					font-size: 1.6em;
					font-weight: 400;
					margin-bottom: 10px;
					line-height: 1.15;

					@media only screen and (max-width: 1199px) {
						font-size: 1.2em;
					}
				}
			}
		}

		img {
			width: 50px;

			@media only screen and (max-width: 1199px) {
				width: 40px;
			}
		}
	}

	&-coming-soon {
		padding-top: 120px;

		@media only screen and (max-width: 1199px) {
			padding-top: 40px;
		}
	}

	&-coming-soon {
		text-align: center;

		&-wrapper {
			width: 400px;
			margin: 0 auto;

			@media only screen and (max-width: 1199px) {
				width: 100%;
			}

			h2 {
				font-weight: 400;
				font-family: "ClashGrotesk-Bold", sans-serif;
				font-size: 1.8em;
				display: flex;
				align-items: center;
				margin-bottom: 10px;

				@media only screen and (max-width: 1199px) {
					justify-content: center;
					font-size: 1.6em;
				}

				span {
					font-family: "ClashGrotesk-Medium", sans-serif;
					font-size: 0.6em;
					margin-right: 15px;
					display: inline-block;
					border: 1px solid #A196FF;
					padding: 10px 15px;
					border-radius: 10px;

					@media only screen and (max-width: 1199px) {
						font-size: 0.4em;
						padding: 5px 10px;
					}
				}
			}

			p {
				font-size: 1.3em;
				line-height: 1.4;
				color: rgba(white, 0.7);

				@media only screen and (max-width: 1199px) {
					font-size: 1.1em;
				}
			}
		}
	}

	&-vp {
		padding: 120px 0 60px;

		@media only screen and (max-width: 1199px) {
			padding: 30px 0;
		}

		&-wrapper {
			grid-template-columns: 1fr 1fr;
			grid-gap: 40px;

			@media only screen and (max-width: 1199px) {
				display: block;
			}
		}

		&-content ,
		&-newsletter {
			border: 1px solid rgba(white, 0.15);
			padding: 80px;
			border-radius: 8px;
			background-color: rgba(#1F1427, 0.5);

			@media only screen and (max-width: 1199px) {
				padding: 30px 20px;
				margin-bottom: 20px;
			}
		}

		&-newsletter {
			@media only screen and (max-width: 1199px) {
				margin-bottom: 20px;
			}
		}

		h2 {
			margin-top: 0;
			font-weight: 400;
			font-family: "ClashGrotesk-Bold", sans-serif;
			font-size: 2.6em;
			display: flex;
			align-items: center;
			margin-bottom: 10px;

			@media only screen and (max-width: 1199px) {
				font-size: 1.8em;
			}

			span {
				display: block;
				font-family: "ClashGrotesk-Variable", sans-serif;
				font-style: italic;
				margin-left: 5px;
			}
		}

		p {
			margin-top: 0;
			margin-bottom: 30px;
			font-size: 1.3em;
			line-height: 1.4;
			color: rgba(white, 0.7);

			@media only screen and (max-width: 1199px) {
				font-size: 1.1em;
			}
		}

		a {
			text-align: center;

			@media only screen and (max-width: 1199px) {
				font-size: 1.1em;
			}
		}
	}
}

.verify-email {
	font-size: 1.05em;
	padding: 10px 20px;
	background-color: #6941c6;
	text-align: center;
	font-family: "ClashGrotesk-Medium", sans-serif;

	a {
		color: inherit;
		text-decoration: none;
		font-family: "ClashGrotesk-Medium", sans-serif;
		margin-right: 15px;
		display: inline-block;
		border: 1px solid #A196FF;
		padding: 5px 10px;
		border-radius: 10px;
		margin-left: 10px;
	}
}