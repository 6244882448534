.leaderboard {
	.container {
		max-width: 600px;
		min-height: 100vh;
		margin: 40px auto;
		font-size: 1.1em;

		a {
			text-decoration: none;
			color: white;
		}
	}


	.grid-listing {
		li {
			width: 100%;
			display: grid;
			gap: 10px;
			grid-template-columns: [col] 50px [col] auto [col] 100px [col] 100px;
			flex-direction: row;
			font-size: 18pt;
			align-items: center;
			.user {
				a {
					display: inline-block;
				}
			}
			.points {
				padding-left: 20px;
				color: #A196FF;
				text-align: right;
			}
			.rank {
				display: flex;
				flex-direction: row;
				align-items: center;
				padding-left: 20px;
				justify-content: flex-end;
				.position {
					font-weight: bold;
					padding-left: 10px;
					font-family: "ClashGrotesk-Medium", sans-serif;
				}
			}
		}
	}
}





.filter-tabs {
	list-style: none;
	margin: 0 auto 60px;
	width: fit-content;
	padding: 0;
	text-align: center;

	li {
		display: inline-block;
		margin: 0 20px;

		a {
			text-decoration: none;
			color: white;
			font-family: "ClashGrotesk-Medium", sans-serif;
			font-size: 1em;
			padding: 0 20px 10px;
			border-bottom: 3px solid transparent;

			&.active {
				border-bottom: 3px solid #a096ff;
				font-family: "ClashGrotesk-Bold", sans-serif;
			}
		}
	}
	&-content {
		ul {
			list-style: none;
			margin: 0;
			padding: 0;

			li {
				margin-bottom: 20px;
			}

			li > div {
				display: grid;
				grid-template-columns: 50px auto auto 30px 100px;
				grid-gap: 20px;
				align-items: center;
				justify-content: space-between;
			}
		}

	}
}