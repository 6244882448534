/**
* Name: Form styles
* Description: General form styles used site wide
* Author: omeiza@bigcabal.com
 */

.form {
	&-email,
	&-password,
	&-username,
	&-text,
	&-textarea {
		text-align: center;

		h2 {
			margin-top: 0;
			font-size: 2.8em;
			line-height: 1;
			font-family: "ClashGrotesk-Bold", sans-serif;

			@media only screen and (max-width: 1199px) {
				font-size: 2.4em;
			}

			span {
				background: -webkit-linear-gradient(left, #5EE2B4, #A196FF);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
			}
		}

		p {
			margin: 0;
			font-size: 1em;

			@media only screen and (max-width: 1199px) {
				font-size: 0.85em;
			}
		}

		p, h2 {
			font-family: "ClashGrotesk-Bold", sans-serif;
		}

		&-main {
			position: relative;
			padding: 20px 0 0;

			label {
				border-radius: 8px;
				font-family: "ClashGrotesk-Bold", sans-serif;
				display: inline-block;
				background-color: var(--background-color);
				color: white;
				position: absolute;
				font-size: 0.8em;
				padding: 3px;
				top: 10px;
				left: 15px;
			}

			input, textarea {
				padding: 17.5px 20px;
				border-radius: 8px;
				font-family: "ClashGrotesk-Regular", sans-serif;
				width: 100%;
				background-color: transparent;
				border: none;
				border: 1px solid rgba(white, 0.15);
				font-size: 1em;
				color: white;

				@media only screen and (max-width: 1199px) {
					font-size: 0.9em;
				}

				&:focus {
					outline: none;
				}
			}

			textarea {
				min-height: 100px;
			}

			.tag-item-container {
				text-align: left;

				.tag-item {
					display: inline-flex;
					margin: 5px;
					border: 1px solid rgba(255, 255, 255, 0.15);
				}
			}
		}

		.forgot-password-link {
			display: flex;
			justify-content: space-between;
			margin: 20px 0 30px;

			@media only screen and (max-width: 1199px) {
				margin: 20px 0;
			}

			a, span {
				cursor: pointer;
				font-family: "ClashGrotesk-Medium", sans-serif;
				font-size: 0.95em;
				color: var(--theme-shade-color);
				text-decoration: none;
			}

		}

		.back-to-previous {
			width: 30px;
			margin-bottom: 20px;
		}
	}

	&-upload-main {
		position: relative;
		width: 100px;
		background-color: rgba(196, 196, 196, 0.24);
		height: 100px;
		margin: 30px auto 15px;
		border-radius: 50%;

		img {
			width: 30px;
			position: absolute;
			top: calc(50% - 15px);
			left: calc(50% - 15px);
			cursor: pointer;
		}

		#file {
			display: none;
		}
	}

	&-password-main {
		.show-password {
			width: 30px;
			height: 30px;
			right: 10px;
			position: absolute;
			top: calc(50% - 5px);
			display: flex;
			align-items: center;
			text-align: center;

			img {
				width: 20px;
				margin: 0 auto;
			}
		}
	}

	&-forgot-password {
		text-align: left;

		h2 {
			margin-bottom: 10px;
		}

		p {
			font-family: "ClashGrotesk-Regular", sans-serif;
			margin-bottom: 30px;
			line-height: 1.3em;
			font-size: 1.1em;
		}

		a, span {
			cursor: pointer;
			color: var(--theme-shade-color);
			text-decoration: none;
		}

		span {
			font-family: "ClashGrotesk-Medium", sans-serif;
		}
	}

	&-checkbox-main {
		padding: 10px 0 20px;
		font-size: 0.9em;
		display: flex;
		align-items: center;

		input {
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			border: 1px solid rgba(white, 0.15);
			border-radius: 4px;
			width: 15px;
			height: 15px;
			background: rgba(40,40,40,0.2);
			transform: scale(1.2);
			margin-right: 10px;

			&:checked {
				background: rgba(white, 1);
			}
		}

		a {
			margin-left: 5px;
			color: white;
			font-family: "ClashGrotesk-Medium", sans-serif;
		}
	}

	&-social {
		ul {
			padding: 0;
			list-style: none;
			margin: 30px auto;
			display: grid;
			grid-template-columns: repeat(3, 48px);
			grid-gap: 25px;
			text-align: center;
			max-width: max-content;

			@media only screen and (max-width: 1199px) {
				margin: 20px auto;
				grid-gap: 10px;
			}

			li {
				border: 1px solid rgba(white, 0.15);
				border-radius: 8px;
				width: 48px;
				height: 48px;
				text-align: center;
			}
		}

		img {
			height: 15px;
			position: relative;
			top: calc(50% - (15px / 2))
		}

		input {
			width: 100%;
		}
	}

	&-username {
		h2 {
			margin-bottom: 20px;
		}

		&-section {
			margin-top: 40px;
		}
	}

	&-error-message,
	&-success-message {
		padding: 15px 20px;
		margin: 30px 0 30px;
		border-radius: 8px;
		font-family: "ClashGrotesk-Medium", sans-serif;
	}

	&-error-message {
		background-color: #ec4242;
		color: white;
	}

	&-success-message {
		background-color: #D9FFF2;
		color: #005437;
	}

	&-text-main {
		&.tag-selector {
			& > div {
				padding: 20px;
				min-height: 60px;
				border: 1px solid rgba(255, 255, 255, 0.15);
				border-radius: 8px;

				span {
					position: relative;
					margin-right: 5px;
					padding: 5px;

					span {
						border-radius: 5px;
						padding: 0 5px;
						display: inline-block;
						background-color: #2D2435;
					}

					button {
						color: white;
						position: absolute;
						right: 7px;
						top: -3px;
					}
				}

				input {
					border: none;
					width: auto;
					padding: 10px 0;
				}
			}
		}
	}

	&-submit {
		margin-top: 20px;

		@media only screen and (max-width: 1199px) {
			margin-top: 10px;
			font-size: 1.1em;
			padding: 15px 20px;
		}
	}
}