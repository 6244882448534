/**
* Name: Grid styles
* Author: omeiza@bigcabal.com
 */

.grid {
	display: grid;

	&-header,
	&-footer {
		grid-template-columns: 140px auto;
		grid-gap: 40px;
		justify-content: space-between;
		align-items: center;

		@media only screen and (max-width: 1199px) {
			grid-template-columns: 100px auto;
			grid-gap: 20px;
		}
	}

	&-footer {
		@media only screen and (max-width: 1199px) {
			display: block;
		}
	}

	&-search {
		grid-template-columns: 120px calc(100% - 120px - 15px);
		grid-gap: 15px;

		@media only screen and (max-width: 1199px) {
			grid-template-columns: 80px calc(100% - 80px - 10px);
			grid-gap: 10px;
		}
	}

	&-heading {
		grid-template-columns: auto auto;
		grid-gap: 40px;
		justify-content: space-between;
		align-items: center;
	}

	&-trending {
		grid-template-columns: repeat(6, 180px);
		column-gap: calc((100% - (180px * 6))/5);

		@media only screen and (max-width: 1199px) {
			grid-template-columns: repeat(2, auto);
			row-gap: 20px;
			column-gap: 20px;
		}
	}

	&-user-memes {
		grid-template-columns: repeat(4, 180px);
		column-gap: calc((1200px - 40px - (180px * 6))/5);
	}

	&-trending-all {
		grid-template-columns: repeat(6, 180px);
		column-gap: calc((100% - (180px * 6))/5);

		li {
			&:nth-of-type(13) {
				grid-row: 3/6;
				grid-column: 1/4;
			}
		}
	}

	&-top {
		grid-template-columns: 3fr 1fr 1fr 1fr;
		column-gap: calc((1200px - 40px - (180px * 6))/5);

		@media only screen and (max-width: 1199px) {
			grid-template-columns: repeat(2, auto);
			row-gap: 20px;
			column-gap: 20px;
		}

		li {

			@media only screen and (min-width: 1200px) {
				&:first-of-type {
					grid-row-start: 1;
					grid-row-end: 4;
				}
			}
		}
	}

	&-hot {
		grid-template-columns: 1fr 1fr 1fr 1fr;
		column-gap: calc((1200px - 40px - (180px * 6))/5);

		@media only screen and (max-width: 1199px) {
			grid-template-columns: repeat(2, auto);
			row-gap: 20px;
			column-gap: 20px;
		}

		@media only screen and (min-width: 1200px) {
			li {
				&:nth-of-type(5) {
					grid-row: 2/5;
					grid-column: 1/3;
				}

				&:nth-of-type(6) {
					grid-row: 2/5;
					grid-column: 3/5;
				}

				&:nth-of-type(7) {
					grid-row: 5/5;
					grid-column: 3/1;
				}

				&:nth-of-type(8) {
					grid-row: 5/5;
					// grid-column: 3/2;
				}
			}
		}
	}

	&-meme {
		grid-template-columns: 300px 560px calc(100% - 560px - 300px - 160px);
		grid-gap: 80px;

		@media only screen and (max-width: 1199px) {
			display: block;

			.ad {
				display: none;
			}
		}

		&-upload {
			grid-template-columns: 300px calc(100% - 300px - 40px);
			grid-gap: 40px;

			@media only screen and (max-width: 1199px) {
				display: block;
	
				.ad {
					display: none;
				}
			}	
		}

		&-form {
			grid-template-columns: 1fr 1fr;
			grid-gap: 40px;
			@media only screen and (max-width: 1199px) {
				grid-template-columns: 1fr;
			}
		}
	}

	&-page {
		&-header {
			grid-template-columns: 380px auto;
			justify-content: space-between;
			grid-gap: 40px;

			@media only screen and (max-width: 1199px) {
				display: flex;
				grid-gap: 0;
			}
		}

		&-content {
			grid-template-columns: 380px auto;

			@media only screen and (max-width: 1199px) {
				display: flex;
				flex-direction: column;
			}
		}
	}

	&-memer {
		grid-template-columns: 150px 70px auto auto;
		align-items: center;
		grid-gap: 40px;

		@media only screen and (max-width: 1199px) {
			grid-template-columns: 90px auto auto;
			grid-gap: 15px;
		}
	}

	&-competition {
		grid-template-columns: 350px auto;
		grid-gap: 20px;

		@media only screen and (max-width: 1199px) {
			display: block;
		}

		&-images {
			grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
			grid-gap: 15px;

			@media only screen and (max-width: 1199px) {
				grid-gap: 10px;
			}
		}

		&-remix {
			grid-template-columns: 1fr 1fr;
			grid-gap: 40px;
			margin-bottom: 0 !important;
			align-items: center;

			img {
				width: 100% !important;
				display: block;
			}

			@media only screen and (max-width: 1199px) {
				display: flex;
				flex-direction: column;
				grid-gap: 20px;
			}
		}
	}

	&-image-source {
		grid-template-columns: 1fr 1fr;
		align-items: center;
		justify-content: space-between;

		p {
			margin-top: 0;
			text-align: right;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			cursor: pointer;
		}
	}
}