/**
* Name: Top component styles
* Author: omeiza@bigcabal.com
 */


.top,
.top-all{
	padding-top: 40px;
	padding-bottom: 40px;

	&-items {
		ul {
			list-style: none;
			padding: 0;
			margin: 0;

			li {
				margin-bottom: calc((1200px - 40px - (180px * 6))/5);

				@media only screen and (max-width: 1199px) {
					margin-bottom: 0;
				}

				a {
					border-radius: 8px;
					display: block;
					width: 100%;
					padding-bottom: 100%;
					overflow: hidden;
					background-position: center center;
					transition: transform .25s ease-out;
					box-shadow: 0 4px 10px 0 rgba(0,0,0,.05),0 2px 4px 0 rgba(0,0,0,.08);
					border: 1px solid #1a1a1a;

					@media only screen and (max-width: 1199px) {
						width: 100%;
						height: 150px;
					}

					img {
						display: block;
						min-width: 100%;
						height: 100%;
						transition: transform .25s ease-out;
						box-shadow: 0 4px 10px 0 rgba(0,0,0,.05),0 2px 4px 0 rgba(0,0,0,.08);
						border: 1px solid #1a1a1a;
					}
				}
			}
		}
	}
}

.top {
	&-items {
		ul {
			li {
				&:first-of-type {
					a {
						width: 100%;
						height: calc((200px * 2) + calc((1200px - 40px - (180px * 6))/5));

						@media only screen and (max-width: 1199px) {
							width: 100%;
							height: 150px;
						}
					}

					img {
						width: 100%;
						height: auto;
						transition: transform .25s ease-out;
						box-shadow: 0 4px 10px 0 rgba(0,0,0,.05),0 2px 4px 0 rgba(0,0,0,.08);
						border: 1px solid #1a1a1a;
					}
				}
			}
		}
	}
}

.top-all {
	.heading-title {
		//font-size: 2.4em;
		display: inline-block;
		font-family: "ClashGrotesk-Medium", sans-serif;
		font-weight: 400;
	}

	.meme-type-list {
		ul {
			list-style: none;
			margin: 0;
			padding: 30px 0 15px;

			li {
				display: inline-block;
				margin-right: 20px;

				a {
					font-family: "ClashGrotesk-Medium", sans-serif;
					font-size: 1.6em;
					color: white;
					text-decoration: none;

					&.active {
						font-family: "ClashGrotesk-Medium", sans-serif;
						color: var(--theme-shade-color);
					}
				}
			}
		}
	}

	&-items {
		&.masonry {
			margin: 0 0 20px;

			li {
				margin-bottom: 20px;
			}

			a {
				width: 100%;
				height: auto;
			}

			img {
				width: 100%;
				height: auto;
				transition: transform .25s ease-out;
				box-shadow: 0 4px 10px 0 rgba(0,0,0,.05),0 2px 4px 0 rgba(0,0,0,.08);
				border: 1px solid #1a1a1a;
			}
		}
	}
}

