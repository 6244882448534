/**
* Name: Footer component styles
* Author: omeiza@bigcabal.com
 */

.footer {
	padding: 40px 0;
	//font-size: 1.1em;

	@media only screen and (max-width: 1199px) {
		font-size: 0.9em;
	}

	&-copyright {
		font-family: "ClashGrotesk-Semibold", sans-serif;

		@media only screen and (max-width: 1199px) {
			margin-bottom: 20px;
		}
	}

	&-links {
		list-style: none;
		margin: 0;
		padding: 0;

		li {
			display: inline-block;
			margin-left: 30px;

			@media only screen and (max-width: 1199px) {
				margin-left: 0;
				display: block;
				margin-bottom: 10px;
			}

			a {
				color: inherit;
				text-decoration: none;
			}
		}
	}
}