/**
* Name: Base styles
* Description: General styles used site wide
* Author: omeiza@bigcabal.com
 */

:root {
	--max-content-width: 1200px;
	--content-width: 100%;
	--background-color: #0B0014;
	--theme-color: #570095;
	--theme-input-color: #1C1224;
	--theme-shade-color: #A196FF;
}

* {
	box-sizing: border-box;
}

html,
body {
	padding: 0;
	margin: 0;
}

body {
	color: white;
	background-color: var(--background-color);
	font-family: "ClashGrotesk-Regular", sans-serif;
	font-weight: normal;
	word-wrap: break-word;
	font-kerning: normal;
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
}

// Buttons
.btn {
	border: none;
	font-weight: 700;
	text-decoration: none;
	border-radius: 8px;
	font-size: 1.1em;

	@media only screen and (max-width: 1199px) {
		font-size: 0.8em;
	}

	&-colored,
	&-multicolored,
	&-white {
		padding: 15px 24px;
		display: inline-block;
		font-family: "ClashGrotesk-Semibold", sans-serif;
		background-color: var(--theme-color);
		color: white;

		@media only screen and (max-width: 1199px) {
			padding: 10px;
		}
	}

	&-multicolored {
		margin-top: 20px;
		width: 100%;
		text-align: center;
		//background-color: transparent;
		background: -webkit-linear-gradient(left, #A196FF, #570095);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		border: 2px solid #570095;
	}

	&-white {
		margin-top: 20px;
		background-color: transparent;
		color: white;
		border: 1px solid white;
	}

	&-text-icon {
		display: flex;
		align-items: center;
		font-size: 0.95em;
		color: white;

		@media only screen and (max-width: 1199px) {
			font-size: 0.8em;
		}

		img {
			width: 20px;
			margin-left: 10px;

			@media only screen and (max-width: 1199px) {
				width: 15px;
				margin-left: 5px;
			}
		}
	}

	&-full {
		width: 100%;
	}

	&-disabled {
		background-color: #170D20;
	}
}

// section headings
.heading {
	&-section {
		margin-bottom: 20px;
	}

	&-title {
		margin: 0;
		font-size: 1.8em;
		font-family: "ClashGrotesk-Medium", sans-serif;
		font-weight: 400;

		@media only screen and (max-width: 1199px) {
			font-size: 1.3em;
		}
	}
}

// auth
.auth {
	background-image: url("../../public/images/auth-back.png");
	position: relative;
	min-height: 100vh;

	&:after,
	&:before {
		top: 0;
		left: 0;
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 1;
	}

	&:after {
		background: linear-gradient(180deg, rgba(0, 0, 0, 0) -23.49%, rgba(0, 0, 0, 0.15) -19.88%, #000000 44.96%, rgba(0, 0, 0, 0.62) 114.84%);
		transform: matrix(-1, 0, 0, 1, 0, 0);
		backdrop-filter: blur(2px);
	}

	&:before {
		background: rgba(0, 0, 0, 0.2);
	}
}

// Overlay
.overlay {
	position: fixed;
	inset: 0;
	z-index: 999;
	pointer-events: auto;
	background: rgba(0,0,0,.5);
}

.meme-uploader {
	&-button {
		position: relative;
		// left: calc(50% - 70px);
		top: calc(50% - 20px);
		border: none;
		border-radius: 5px;
		text-align: center;
		font-family: "ClashGrotesk-Medium", sans-serif;
		font-size: 1em;
		content: "Upload from device";
		color: white;
		padding: 10px 20px;
		background-color: #570095;

	}

	&-wrapper {
		color: #261C2E;
		position: relative;
		margin-top: 20px;
		border: 1px dashed #FFFFFF;
		background-color: #261C2E;
		height: 200px;
		text-align: center;

		#file {
			display: none;
		}

		&.meme-upload-area {
			padding: 17.5px 20px;
			border-radius: 8px;
			background-color: transparent;
			border: 1px solid rgba(255, 255, 255, 0.15);
		}
	}

	&-more {
		cursor: pointer;
		text-align: center;
		margin-top: 20px;
		font-weight: 500;
		color: #A196FF;
		font-family: "ClashGrotesk-Medium", sans-serif;
	}

	&-notice {
		p {
			color: rgba(white, 0.4);
			text-align: center;
			font-size: 0.9em;
			margin-top: 5px;
		}
	}
}

// Rounded images
.rounded {
	border-radius: 50%;
}
