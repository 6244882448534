/**
* Name: Menu component styles
* Author: omeiza@bigcabal.com
 */

.menu {
	animation-duration: 0.5s;
	animation-name: slideIn;
	max-width: 300px;
	width: 100%;
	background-color: var(--theme-input-color);
	position: absolute;
	right: 0;
	height: 100%;
	padding: 80px 40px;
	-webkit-transition: transform 0.3s ease-out;
	-moz-transition: transform 0.3s ease-out;
	transition: transform 0.3s ease-out;
	z-index: 9999;

	@media only screen and (max-width: 1199px) {
		padding: 40px 20px;
		max-width: 280px;
	}

	section {
		margin-bottom: 30px;
		padding: 30px 10px;
		position: relative;

		@media only screen and (max-width: 1199px) {
			padding: 10px 10px;
			margin-bottom: 20px;
		}

		&:after {
			content: "";
			position: absolute;
			height: 1px;
			width: 100%;
			left: 0;
			bottom: 0;
			background: -webkit-linear-gradient(left, #A196FF, #570095);
		}

		&:last-of-type {
			&:after {
				display: none;
			}
		}
	}

	ul {
		list-style: none;
		padding: 0;
		margin: 0;

		li {
			font-size: 0.9em;
			margin-bottom: 30px;

			@media only screen and (max-width: 1199px) {
				margin-bottom: 20px;
			}

			img {
				width: 14px;
				margin-left: 10px;
			}
		}

		li, a {
			font-family: "ClashGrotesk-Medium", sans-serif;
		}

		a {
			text-decoration: none;
			color: inherit;
			display: flex;
		}
	}

	&-close {
		width: 20px;
		position: absolute;
		top: 60px;
		right: 40px;

		@media only screen and (max-width: 1199px) {
			top: 20px;
			right: 30px;
		}
	}
}

@keyframes slideIn {
	from {
		transform: translate(100%, 0);
	}

	to {
		transform: translate(0, 0);
	}
}