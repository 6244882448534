/**
* Name: Trending component styles
* Author: omeiza@bigcabal.com
 */

.trending,
.trending-all {
	padding-top: 40px;
	padding-bottom: 40px;

	@media only screen and (max-width: 1199px) {
		padding-top: 20px;
		padding-bottom: 20px;
	}

	&-heading-select {
		display: flex;
		position: relative;

		select {
			position: relative;
			margin-left: 5px;
			font-family: "ClashGrotesk-Medium", sans-serif;
			width: 95px;
			background-color: transparent;
			border: none;
			font-size: 1em;
			color: var(--theme-shade-color);
			text-decoration: underline;
			-webkit-appearance: none;
			-moz-appearance: none;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;

			@media only screen and (max-width: 1199px) {
				width: 70px;
			}

			&::-ms-expand {
				display: none;
			}

			&:focus {
				outline: none;
			}
		}

		&:after {
			position: absolute;
			content: "";
			top: calc(50%);
			right: -15px;
			width: 0;
			height: 0;
			border: 6px solid transparent;
			border-color: white transparent transparent transparent;
		}
	}

}

.trending-all {
	.heading-title {
		font-size: 2.4em;
		display: inline-block;
		font-weight: 400;

		@media only screen and (max-width: 1199px) {
			font-size: 1.3em;
		}
	}

	select {
		width: 125px;

		@media only screen and (max-width: 1199px) {
			width: 70px;
		}
	}
}
