.banner {
	margin: 30px 0;

	&-competition {
		padding: 40px;
		background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(93.5deg, #570095 -5.23%, #8A49D6 109.09%);

		@media only screen and (max-width: 1199px) {
			padding: 20px;
		}

		h2 {
			margin: 0 0 10px;
			font-family: ClashGrotesk-Bold,sans-serif;
			font-size: 2em;
			line-height: .95em;

			@media only screen and (max-width: 1199px) {
				font-size: 1.5em;
				text-align: center;
				line-height: 1.15em;
			}
		}

		p {
			font-size: 1.1em;

			@media only screen and (max-width: 1199px) {
				font-size: 1em;
				text-align: center;
				margin-bottom: 30px;
				margin-top: 10px;
			}
		}

		&-info {
			margin-bottom: 30px;

			@media only screen and (max-width: 1199px) {
				margin-bottom: 15px;
			}
		}

		.btn-colored {
			background-color: #9A2BC2;
			border-radius: 0;
			margin-right: 30px;

			@media only screen and (max-width: 1199px) {
				padding: 0;
				background-color: transparent;
				display: inline-block;
				border-bottom: 1px solid white;
			}
		}

		.links {
			@media only screen and (max-width: 1199px) {
				display: flex;
				justify-content: space-between;
			}

			.ord-link {
				color: white;
				font-size: 1.2em;
				display: inline-block;
				text-decoration: none;
				border-bottom: 1px solid white;

				@media only screen and (max-width: 1199px) {
					font-size: 0.75em;
				}
			}
		}

		.images {
			list-style: none;
			margin: 0;
			padding: 0;

			img {
				width: 100%;
			}
		}

		&-remix {
			padding: 0;

			.description {
				padding: 40px;

				@media only screen and (max-width: 1199px) {
					padding: 0 20px 20px;
					order: 2;
				}
			}
		}
	}

	&-memer {
		padding: 20px 40px;
		background: linear-gradient(105.26deg, #6941C6 21.98%, #5A0E75 75.48%);
		border-radius: 4px;

		@media only screen and (max-width: 1199px) {
			padding: 15px;
		}

		.memer-text {
			font-size: 1.8em;
			font-family: "ClashGrotesk-Bold", sans-serif;
			line-height: 0.95em;

			@media only screen and (max-width: 1199px) {
				font-size: 1.2em;
			}
		}

		.memer-avatar {
			width: 70px;

			@media only screen and (max-width: 1199px) {
				width: 60px;
				order: 3;
			}

			a {
				border-radius: 100%;
				display: block;
				overflow: hidden;
				padding-bottom: 100%;
				position: relative;
				width: 70px;
				height: 70px;
				box-shadow: 2px 0 10px #a196ff;

				@media only screen and (max-width: 1199px) {
					width: 60px;
					height: 60px;
				}
			}

			img {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				width: 100%;
				height: 100%;
				display: block;
				object-fit: cover;
			}
		}

		.memer-count {
			font-size: 1.3em;
			font-family: "ClashGrotesk-Bold", sans-serif;

			@media only screen and (max-width: 1199px) {
				font-size: 1em;
				order: 2;
				text-align: right;
			}

			span {
				display: block;

				&:last-of-type {
					a {
						color: inherit;
						text-decoration: none;
						font-family: "ClashGrotesk-Medium", sans-serif;
					}
				}
			}
		}
	}

	.desktop-leaderboard-link,
	.mobile-leaderboard-link {
		a {
			font-size: 1.1em;
			color: inherit;
			text-decoration: none;
			font-family: "ClashGrotesk-Medium", sans-serif;
		}
	}

	.desktop-leaderboard-link {
		text-align: right;

		@media only screen and (max-width: 1199px) {
			display: none;
		}
	}

	.mobile-leaderboard-link {
		padding: 15px 20px 20px;
		text-align: center;
		font-size: 0.9em;

		a {
			width: max-content;
			display: flex;
			align-items: center;
			text-align: center;
			margin: 0 auto;
		}

		img {
			width: 18px;
			margin-right: 10px;
		}

		@media only screen and (min-width: 1200px) {
			display: none;
		}
	}
}